import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, FormControl, FormLabel, Input, Button, VStack } from '@chakra-ui/react';
import axios from 'axios';

function NewThreadForm() {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('/api/threads', { title, author, replies: [] })
      .then(() => navigate('/'))
      .catch((error) => console.error('Error creating thread:', error));
  };
  return (
    <Box maxW="md" mx="auto">
      <Heading as="h1" size="lg" mb={6}>
        Create a New Thread
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter thread title"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Author</FormLabel>
            <Input
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              placeholder="Your name"
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" width="full">
            Post Thread
          </Button>
        </VStack>
      </form>
    </Box>
  );
}
export default NewThreadForm;
