import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Text, VStack, Divider, StackDivider } from '@chakra-ui/react';
import axios from 'axios';

function Thread() {
  const { id } = useParams();
  const [thread, setThread] = useState(null);
  useEffect(() => {
    axios
      .get(`/api/threads/${id}`)
      .then((response) => setThread(response.data || null))
      .catch((error) => {
        console.error('Error fetching thread:', error);
        setThread(null);
      });
  }, [id]);
  if (!thread) return <Text>Loading or thread not found...</Text>;
  return (
    <Box>
      <Heading as="h1" size="lg" mb={4}>
        {thread.title || 'Untitled'}
      </Heading>
      <Text fontSize="sm" color="gray.500" mb={6}>
        By {thread.author || 'Unknown'} on {thread.date ? new Date(thread.date).toLocaleDateString() : 'N/A'}
      </Text>
      <Divider mb={6} />
      <Heading as="h2" size="md" mb={4}>
        Replies
      </Heading>
      {!thread.replies || thread.replies.length === 0 ? (
        <Text>No replies yet.</Text>
      ) : (
        <VStack divider={<StackDivider />} spacing={4} align="stretch">
          {thread.replies.map((reply) => (
            <Box key={reply.id} p={3} bg="gray.50" borderRadius="md">
              <Text>{reply.text || 'No content'}</Text>
              <Text fontSize="sm" color="gray.500">
                - {reply.author || 'Anonymous'}
              </Text>
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
}
export default Thread;
