import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Box, Flex, Button } from '@chakra-ui/react';
import ThreadList from './components/ThreadList';
import Thread from './components/Thread';
import NewThreadForm from './components/NewThreadForm';

function App() {
  return (
    <Router>
      <Box p={4}>
        <Flex as="nav" mb={6} gap={4}>
          <Button as={Link} to="/" colorScheme="blue" variant="outline">
            Home
          </Button>
          <Button as={Link} to="/new-thread" colorScheme="blue">
            New Thread
          </Button>
        </Flex>
        <Routes>
          <Route path="/" element={<ThreadList />} />
          <Route path="/thread/:id" element={<Thread />} />
          <Route path="/new-thread" element={<NewThreadForm />} />
        </Routes>
      </Box>
    </Router>
  );
}
export default App;
