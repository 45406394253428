import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import axios from 'axios';

function ThreadList() {
  const [threads, setThreads] = useState(null);
  useEffect(() => {
    axios
      .get('/api/threads')
      .then((response) => setThreads(response.data || []))
      .catch((error) => {
        console.error('Error fetching threads:', error);
        setThreads([]);
      });
  }, []);
  if (!threads) return <Text>Loading...</Text>;
  return (
    <Box>
      <Heading as="h1" size="lg" mb={4}>
        Forum Threads
      </Heading>
      {threads.length === 0 ? (
        <Text>No threads yet. Be the first to start one!</Text>
      ) : (
        <VStack spacing={4} align="stretch">
          {threads.map((thread) => (
            <Box
              key={thread.id}
              p={4}
              shadow="md"
              borderWidth="1px"
              borderRadius="md"
              _hover={{ shadow: 'lg' }}
            >
              <Text fontSize="xl" fontWeight="bold">
                <Link to={`/thread/${thread.id}`}>{thread.title}</Link>
              </Text>
              <Text fontSize="sm" color="gray.500">
                By {thread.author} on {thread.date ? new Date(thread.date).toLocaleDateString() : 'N/A'}
              </Text>
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
}
export default ThreadList;
